.sectionSobre {
    display: flex;
    flex-direction: row;
    margin: auto;
    height: 90vh;
    max-width: 1400px;
    align-items: center;
    justify-content: space-evenly;
    z-index: 1;
}

.titleContainer {
    font-family: gilroy;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 620px;
    margin: 10px;
}

.titleContainer h1 {
    margin: 0px;
    font-size: 180px;
    line-height: 140px;
    text-align: start;
    font-weight: bold;
    margin-bottom: 40px;
}

.texto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 620px;
    height: 400px;
    font-family: amsiPro;
    font-weight: bold;
    margin: 10px;
}

.texto p {
    text-align: center;
    margin-bottom: 20px;
}
/* Mobile */
@media (max-width: 700px) {
    .sectionSobre {
        flex-direction: column;
        padding-top: 0px;
        height: auto;
        max-width: 100vw;
        
    }
    .titleContainer {
        width: 300px;
        margin: 10px;
        height: auto;
    }
    
    .titleContainer h1 {
        font-size: 80px;
        line-height: 70px;
        text-align: start;
        font-weight: bold;
        margin-bottom: 20px; 
    }
    .texto {
        justify-content: flex-start;
        width: 300px;
        height: 400px;
        margin: 10px;
    }
  }