:root {
  --ucg-orange: #df6f1a;
  --ucg-dark: rgb(51, 51, 51);
}

@font-face {
  font-family: amsiPro;
  font-style: normal;
  src: url("./assets/AmsiPro-Light.otf");
}

@font-face {
  font-family: gilroy;
  font-style: normal;
  src: url("./assets/Gilroy\ Black.otf");
}

body {
  margin: 0px;
  font-family: amsiPro;
  color: white;
  background-color: var(--ucg-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro {
  max-width: 90vw;
  opacity: 0;
  animation: introAnim 500ms ease-in 3s 1 normal forwards;
}

@keyframes introAnim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.disclaimer {
  font-size: 12px;
}

.disclaimer-home {
  opacity: 0;
  font-size: 12px;
  padding-bottom: 40px;
  animation: introAnim 500ms ease-in 3s 1 normal forwards;
}

.chatLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-family: gilroy;
  text-decoration: none;
}

.App-logo {
  pointer-events: none;
}

.home-layout {
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  align-items: center;
}

.main-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subtitle {
  margin: 0px 0px 10px 0px;
  opacity: 0;
  animation: introAnim 500ms ease-in 3s 1 normal forwards;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 200px;
  font-weight: bold;
  font-family: gilroy;
  overflow: hidden;
  /* height: 30vh; */
  line-height: 160px;
  padding: 20px 20px 10px 20px;
  position: relative;
}

.video-intro {
  max-width: 90vw;

  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.video-loop {
  max-width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  opacity: 0;
  animation: videoFade 100ms linear 2s 1 normal forwards;
}

@keyframes videoFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.text-anim {
  animation: blinkTextWhite 250ms linear 0s infinite alternate;
  /* -webkit-text-stroke: 4px var(--ucg-orange); */
  color: white;
}

.text-anim-back {
  animation: blinkText 250ms linear 2500ms infinite alternate;
  color: var(--ucg-dark);
  -webkit-text-stroke: 2px var(--ucg-dark);
  -webkit-text-fill-color: var(--ucg-dark);
}

.text-a {
  -webkit-text-stroke: 4px var(--ucg-orange);
  -webkit-text-stroke-color: var(--ucg-orange);
  -webkit-text-stroke-width: 2px;
  color: var(--ucg-dark);
  /* text-shadow: -1px -1px 0 var(--ucg-orange), 1px -1px 0 var(--ucg-orange), -1px 1px 0 var(--ucg-orange), 1px 1px 0 var(--ucg-orange); Sombras para simular el trazo */

}

@keyframes blinkText {
  0% {
    -webkit-text-stroke-color: var(--ucg-dark);
  }

  50% {
    -webkit-text-stroke-color: var(--ucg-dark);
  }

  51% {
    -webkit-text-stroke-color: var(--ucg-orange);
  }

  100% {
    -webkit-text-stroke-color: var(--ucg-orange);
  }
}

@keyframes blinkTextWhite {
  0% {
    -webkit-text-stroke: 2px var(--ucg-dark);
    color: var(--ucg-dark);
  }

  50% {
    -webkit-text-stroke: 2px var(--ucg-dark);
    color: var(--ucg-dark);
  }

  51% {
    -webkit-text-stroke: 2px white;
    color: white;
  }

  100% {
    -webkit-text-stroke: 2px white;
    color: white;
  }
}

.text-shadow {
  animation: glowAnimation 3s linear 2.6s 1 normal forwards;
}

@keyframes glowAnimation {
  from {
    font-size: 12vw;
    text-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
  }

  to {
    font-size: 13vw;
    text-shadow: 0px 0px 30px white;
  }
}

header {
  margin-top: 100px;
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.homebg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  background-color: var(--ucg-dark);
  z-index: -1;
  overflow: hidden;
}

.homebg video {
  height: 1080px;
}

.outlined-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-weight: 200;
  font-size: 20px;
  width: 200px;
  border: 1px solid var(--ucg-orange);
  background-color: var(--ucg-orange);
  border-radius: 12px;
  color: white;
  font-family: amsiPro;
  text-decoration: none;
  font-weight: bold;
}

header a {
  font-size: 18px;
  text-decoration: none;
}

a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

.icon-button {
  border-radius: 50%;
  border: none;
  margin: 0px;
  height: 50px;
  width: 50px;
  transition: ease 0.15s;
  background-color: rgba(255, 255, 255, 0);
  border: 1px white solid;
  color: white;
  cursor: pointer;
}

.icon-button:hover {
  transition: ease 0.15s;
  border: 2px var(--ucg-orange) solid;
  padding-left: 6px;
  color: var(--ucg-orange);
}

.loading {
  width: 60vw;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
}

.bar-container {
  position: relative;
  height: 20px;
  overflow: hidden;
  width: 100%;
  border-radius: 6px;
  margin-left: 20px;
}

.loading-bar {
  position: absolute;
  left: -200px;
  width: 20vw;
  height: 20px;
  border-radius: 6px;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 30%,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 1) 70%,
      rgba(255, 255, 255, 0) 100%);
  animation: name duration timing-function delay iteration-count direction fill-mode;
  animation: loading-animation 2s ease-in-out 0s infinite forwards;
}

@keyframes loading-animation {
  0% {
    left: -200px;
  }

  100% {
    left: 100%;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .chatLogo {
    font-size: 1rem;
  }

  .chatLogo img {
    height: 40px;
    width: auto;
  }

  header {
    margin-top: 20px;
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  header img {
    height: 40px;
  }

  .loading {
    width: 80vw;
  }

  .loading-bar {
    /* left: -600px; */
    width: 40vw;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {}

@media only screen and (max-width: 700px) {
  .homebg video {
    height: 120vh;
  }

  .logo-container {
    font-size: 80px;
    line-height: 60px;
    max-width: 90vw;
    overflow: hidden;
    letter-spacing: 4px;

  }

  .disclaimer {
    font-size: 8px;
    max-width: 260px;
  }
  .disclaimer-home {
    padding:10px 40px;
    font-size: 8px;
    
  }

  .text-a,
  .text-anim-back {
    -webkit-text-stroke-width: 1px;
  }

  @keyframes blinkText {
    0% {
      -webkit-text-stroke-color: var(--ucg-dark);
    }

    50% {
      -webkit-text-stroke-color: var(--ucg-dark);
    }

    51% {
      -webkit-text-stroke-color: var(--ucg-orange);
    }

    100% {
      -webkit-text-stroke-color: var(--ucg-orange);
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .logo-container {
    letter-spacing: 0px;
  }

  .text-a,
  .text-anim-back {
    -webkit-text-stroke-width: 1px;
  }

  @keyframes blinkText {
    0% {
      -webkit-text-stroke-color: var(--ucg-dark);
    }

    50% {
      -webkit-text-stroke-color: var(--ucg-dark);
    }

    51% {
      -webkit-text-stroke-color: var(--ucg-orange);
    }

    100% {
      -webkit-text-stroke-color: var(--ucg-orange);
    }
  }
}

@media (prefers-reduced-motion: no-preference) {}