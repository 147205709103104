.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  color: white;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--max-width);
  padding-top: 20px;
  height: 80px;
  background-color: var(--foreground-rgb);
}

.inputPrompt {
  height: 64px;
  width: 60vw;
  border-radius: 32px;
  font-size: 18px;
  border: 1px solid white;
  padding: 0px 70px 0px 20px;
  margin-bottom: 4px;
  background-color: var(--ucg-dark);
  color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  caret-color: var(--ucg-orange);
}
.inputPrompt::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.inputPrompt[type=text]:focus {
  border: 1px solid var(--ucg-orange);
  outline: 1px solid var(--ucg-orange);
}

.chatContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.chatContainer::-webkit-scrollbar {
  width: 10px;
}
.chatContainer::-webkit-scrollbar-track {
  background-color: var(--foreground-rgb);
}
.chatContainer::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 6px;
}

.promptContainer {
  width: 60vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px 0px;
  text-align: start;
  /* border-bottom: 2px solid tomato; */
  font-size: 18px;
}
.promptContainer div{
  margin-top: 0px;
}
.promptContainer p {
  /* margin-top: 0px; */
}
.promptContainer table {
  border-radius: 20px;
  padding: 10px;
  border: 1px solid gray;
  overflow: hidden;
  border-collapse: collapse;
  margin: 20px 0px 40px 0px;
}
.promptContainer table thead {
  min-width: 200px;
  border-radius: 20px;
  height: 60px;
  background-color: rgba(128, 128, 128, 0.3);
}

.promptContainer table tr:nth-child(even) {
  background-color: rgba(174, 173, 173, 0.1);
}

.promptContainer table tr {
  /* padding-left: 20px; */
  min-height: 60px;
  /* border-bottom: 1px solid gray; */
}
.promptContainer table td {
  padding: 10px 0px;
}
.promptContainer * {
  margin: 1rem 0;
}

.promptAvatar {
  height: 40px;
  width: 200px;
  color: var(--ucg-dark);
  border-radius: 6px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-weight: bold;
  color: white;
}
.promptAvatar h2{
  font-weight: bold;
  font-family: gilroy;
  font-size: 32px;

}
.promptAvatar img{
  margin-right: 4px;
}
.promptUserContent {
  background-color: var(--ucg-orange);
  padding: 20px 50px;
  border-radius: 10px;
}
.promptUserContent p, .promptMarciaContent p{
  margin: 0px;
  padding: 0px;
}
.promptMarciaContent{
  padding: 20px 50px;
  border-radius: 10px;
  border: 2px solid var(--ucg-orange);
}

.inputContainer {
  position: relative;
}
.sendButton {
  position: absolute;
  right: 10px;
  top: 8px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 100px 0px 40px 0px;
  grid-auto-rows: minmax(100px, auto);
}
.cardSugerencia {
  background-color: var(--ucg-orange);
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--ucg-orange);
  border-radius: 20px;
  cursor: pointer;
  color: white;
  width: 500px;
  font-size: 16px;
}
.darkSugerencia {
  background-color: var(--ucg-dark);
  border: 1px solid white;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

@media (prefers-reduced-motion) {
  .card:hover span {
    transform: none;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .main {
    height: 99dvh;
    width: 99vw;
  }
  .chatLogo {
    color: purple;
  }
  .promptContainer {
    width: 80vw;
    min-height: 64px;
    /* border-bottom: 2px solid tomato; */
    font-size: 1rem;
  }
  .inputPrompt {
    width: auto;
    margin-bottom: 0px;
  }
  .inputContainer {
    padding-bottom: 10px;
  }
  .wrapper {
    grid-template-columns: auto auto;
    margin: 20px 0px 40px 0px;
  }
  .cardSugerencia {
    width: 140px;
    font-size: 10px;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media (prefers-color-scheme: dark) {
}
